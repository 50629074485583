import { createStore, createLogger } from 'vuex';
import register from "./module/register";
import faq from "./module/faq";
import period from "./module/period";
import auth from "./module/auth";
import articles from "./module/articles";
import appeal from "./module/appeal";
import calendar from "./module/calendar";
import resetPassword from "./module/resetPassword";


const debug = process.env.NODE_ENV !== 'production';

export default function () {
  return createStore({
    modules: {
      register,
      faq,
      period,
      auth,
      articles,
      appeal,
      calendar,
      resetPassword,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
  });
}
