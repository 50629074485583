<template>
  <header class="header">
    <div class="container">
      <div class="header-section">
        <div class="flex logo-wrap">
          <logo />
          <logo-education />
        </div>
        <h-contacts class="desktop"  @setMenu="() =>{
          menuActive = !menuActive
        }"/>
      </div>
    </div>
    <div class="divider" />
    <div class="container">
      <div class="header-section">
        <div class="flex">
          <router-link
            class="header-link hover-effect"
            :to="$i18nRoute({ name: 'Instruction' })"
            >{{ $t("header.instruction") }}
          </router-link>
          <router-link
            class="header-link hover-effect"
            :to="$i18nRoute({ name: 'Announcing' })"
            >{{ $t("header.announcing") }}
          </router-link>
          <router-link
            class="header-link hover-effect"
            :to="$i18nRoute({ name: 'Question' })"
            >{{ $t("header.question") }}
          </router-link>
          <div
            class="burger-menu"
            :class="{ active: menuActive }"
            @click="menuActive = !menuActive"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {{langActive}}
        <h-contacts class="mobile" @setMenu="() =>{
          menuActive = !menuActive
        }"/>
        <div class="flex right-links">
          <router-link
            :to="$i18nRoute({ name: 'AccountInfo' })"
            class="cabinet-link hover-effect"
          >
            <svg
              width="2.5rem"
              height="2.5rem"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20.5" r="20" fill="#377DFF" />
              <path
                d="M20 20.5C22.21 20.5 24 18.71 24 16.5C24 14.29 22.21 12.5 20 12.5C17.79 12.5 16 14.29 16 16.5C16 18.71 17.79 20.5 20 20.5ZM20 22.5C17.33 22.5 12 23.84 12 26.5V28.5H28V26.5C28 23.84 22.67 22.5 20 22.5Z"
                fill="white"
              />
            </svg>
            <span>{{ $t("header.personInfo") }}</span>
          </router-link>
          <primary-link
            v-if="!accessToken"
            :title="$t('header.register')"
            routeName="Register"
            isOutlined
          />
          <primary-link
            :title="$t('header.registrationExamPBT')"
            routeName="RegistrationExem"
          />
          <primary-link
            :title="$t('header.registrationExamIBT')"
            :to="$i18nRoute({ name: 'RegistrationIBT'})"
          />
        </div>
      </div>
    </div>
    <div class="divider desktop" />
    <div class="bottom-links flex mobile">
      <router-link
        :to="$i18nRoute({ name: 'AccountInfo' })"
        class="bottom-link flex-1"
        >{{ $t("header.personInfo") }}</router-link
      >
      <router-link
        v-if="!accessToken"
        :to="$i18nRoute({ name: 'Register' })"
        class="bottom-link flex-1"
        >{{ $t("header.register") }}</router-link
      >
      <router-link
        :to="$i18nRoute({ name: 'RegistrationExem' })"
        class="bottom-link flex-1"
        >{{ $t("header.registrationExamPBT") }}</router-link
      >
      <router-link
        :to="$i18nRoute({ name: 'RegistrationIBT'})"
        class="bottom-link flex-1"
        >{{ $t("header.registrationExamIBT") }}</router-link
      >
    </div>
    <HeaderMenu :active="menuActive" @closeMenu="menuActive = false" />
  </header>
</template>

<script setup>
import PrimaryLink from "@/components/PrimaryLink";
import LogoEducation from "@/components/LogoEducation";
import hContacts from "@/components/HeaderContacts";
import Logo from "@/components/Logo";
import HeaderMenu from "@/components/HeaderMenu";
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const menuActive = ref(false);

const accessToken = computed(() => store.getters["auth/getAccessToken"]);
</script>

<style lang="scss" scoped>
.header {
  position: relative;

  .flex {
    display: flex;
    align-items: center;

    &-1 {
      flex: 1;
    }
  }

  .mobile {
    display: none;
  }

  .logo-wrap {
    padding-right: 0.63rem;
  }

  .divider {
    width: 100%;
    height: 0.0625rem;
    background: #d0d4ea;
  }

  &-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.9375rem 0;
  }

  svg {
    margin-right: 0.625rem;
  }

  &-link {
    font-weight: 500;
    font-size: 0.8em;
    color: var(--dark-blue);
    margin-right: 1.875rem;
    opacity: 0.8;
    text-align: center;
  }

  .cabinet-link {
    font-weight: 500;
    font-size: 0.8em;
    line-height: 180%;
    min-width: max-content;
    display: flex;
    align-items: center;
    color: var(--primary-blue);
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    svg {
      min-width: 2.5rem;
      min-height: 2.5rem;
      margin-right: 0.9375rem;
    }
  }

  .primary-link {
    margin-left: 1.875rem;
  }

  .burger-menu {
    display: none;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: 1.4rem;
    transition: all 0.3s ease;

    &.active {
      transform: rotate(90deg);
    }

    span {
      width: 1.875rem;
      height: 0.2rem;
      display: block;
      background: var(--dark-blue);
    }
  }
}

@media screen and (max-width: 1280px) {
  .header {
    svg {
      margin-right: 0;
    }
    .phone {
      margin: 0 0.5rem;
      span {
        display: none;
      }
    }

    .cabinet-link {
      svg {
        width: 1.5625rem;
        height: 1.5625rem;
      }
    }

    &-link {
      display: none;
    }

    .burger-menu {
      display: flex;
      margin-right: 0.63rem;
    }
  }
}
@media screen and (max-width: 700px) {
  .header {
    .cabinet-link {
      span {
        display: none;
      }
      svg {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .header {
    .burger-menu {
      height: 1rem;

      span {
        width: 1.25rem;
        height: 0.13rem;
      }
    }
    .right-links {
      display: none;
    }

    .mobile {
      display: flex;
    }

    .desktop {
      display: none;
    }

    .bottom-links {
      width: 100%;
      justify-content: space-around;
      border-top: 0.0625rem solid var(--primary-blue);
      border-bottom: 0.0625rem solid var(--primary-blue);
      padding: 0.625rem 0.3125rem;
      .bottom-link {
        font-size: 0.63rem;
        text-align: center;
        padding: 0 0.2rem;
        color: var(--primary-blue);
        font-weight: 500;
      }
    }
  }
}
</style>
