import * as apiV1 from "@/network/v1/index";
const token = JSON.parse(localStorage.getItem("token"));

const state = {
  appealsList: [],
  appealsDetail: [],
  allChoices: [],
  adminAppeal: [],
  access: token ? token : null,
  lastEvents: [],
  examTypes: [],
};

// getters
const getters = {
  appealsList: (state) => state.appealsList,
  appealsDetail: (state) => state.appealsDetail,
  allChoices: (state) => state.allChoices,
  adminAppeal: (state) => state.adminAppeal,
  lastEvents: (state) => state.lastEvents,
  examTypes: (state) => state.examTypes,
};

// actions
const actions = {
  getAppealsList({ commit }, cb = () => {}) {
    apiV1.getAppealsList((data) => {
      commit("setAppealsList", data);
      cb();
    }, JSON.parse(localStorage.getItem("token")));
  },

  getAppealsDetail({ commit }, { сb = () => {}, id }) {
    apiV1.getAppealsDetail(
      (data) => {
        commit("setAppealsDetail", data);
        сb();
      },
      JSON.parse(localStorage.getItem("token")),
      id
    );
  },

  getAllChoices({ commit }, cb = () => {}) {
    apiV1.getAllChoices((data) => {
      commit("setAllChoices", data);
      cb();
    });
  },

  getAdminAppealsDetail({ commit }, { cb = () => {}, id }) {
    apiV1.getAdminAppealsDetail((data) => {
      commit("setAdminAppealsDetail", data);
      cb();
    }, id);
  },

  getLastEvents({ commit }, cb = () => {}) {
    // TODO
    // send request and get data
    // then if count is more than count of returned objects than
    // send request again with offset
    apiV1.getLastEvents((data) => {
      commit("setLastEvents", data);
      cb();
    }, 20, 0);
  },

  getExamTypes({ commit }, cb = () => {}) {
    apiV1.getExamTypes((data) => {
      commit("setExamTypes", data);
      cb();
    });
  },
};

// mutations
const mutations = {
  setAppealsList(state, value) {
    state.appealsList = value;
  },
  setAppealsDetail(state, value) {
    state.appealsDetail = value;
  },
  setAllChoices(state, value) {
    state.allChoices = value;
  },
  setAdminAppealsDetail(state, value) {
    state.adminAppeal = value;
  },
  setLastEvents(state, value) {
    state.lastEvents = value.reverse();
  },
  setExamTypes(state, value) {
    state.examTypes = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
