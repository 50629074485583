<template>
  <router-link :to="$i18nRoute({ name: 'Home' })" class="logo">
    <img src="@/assets/logo/logo.png" alt="Logo" />
  </router-link>
</template>
<script setup></script>
<style lang="scss" scoped>
.logo {
  margin-right: 1.125rem;
  display: flex;
  align-items: center;
  
  img {
    width: 8rem;
    background: none;
  }
}

@media screen and (max-width: 576px) {
  .logo {
    img {
      width: 6.25rem;
    }
  }
}
</style>
