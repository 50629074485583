<template>
  <a
    href="https://www.tashkec.uz/"
    target="_blank"
    class="logo-education-center family-poppins"
  >
    <img src="@/assets/logo/logo_2.png" alt="" />
    <span>
      {{ $t("educationCenter") }}
    </span>
  </a>
</template>

<script setup></script>

<style lang="scss" scoped>
.logo-education-center {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 144%;
  cursor: pointer;

  img {
    width: 3.75rem;
    margin-right: 0.625rem;
  }

  span {
    max-width: 15rem;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .logo-education-center {
    font-size: 0.65em;

    span {
      max-width: 10rem;
    }

    img {
      width: 3.13rem;
    }
  }
}
</style>
