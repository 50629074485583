import * as apiV1 from "@/network/v1/index";
// const user = JSON.parse(localStorage.getItem('user'));
if (localStorage.getItem("token") === "undefined") {
  localStorage.removeItem("token");
}
const token =
  localStorage.getItem("token") === "undefined"
    ? ""
    : JSON.parse(localStorage.getItem("token"));

const state = {
  loggedIn: false,
  user: null,
  access: token ? token : null,
};

// getters
const getters = {
  getUser: (state) => state.user,
  getAccessToken: (state) => state.access,
  getLoggedIn: (state) => state.loggedIn,
};

// actions
const actions = {
  getMe({ commit }, { cb = () => { } }) {
    apiV1.getMe(
      (user) => {
        // localStorage.setItem('user', JSON.stringify(user));
        commit("loginSuccess", user);
        cb();
      },
      JSON.parse(localStorage.getItem("token")),
      (error) => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },
  updateUser({ commit }, { cb = () => { }, formData, err = () => { } }) {
    apiV1.updateUser(
      (user) => {
        commit("loginSuccess", user);
        cb(user);
      },
      JSON.parse(localStorage.getItem("token")),
      formData,
      err
    );
  },
  getToken({ commit }, { cb = () => { }, user, err }) {
    apiV1.getTokens(
      (data) => {
        commit("setAccess", data.access);
        commit("setRefresh", data.refresh);
        commit("loginSuccess", user);
        if (data.access) {
          localStorage.setItem("token", JSON.stringify(data.access));
        }
        console.log(data.error);
        cb(data);
      },
      user,
      err
    );
  },
  verifyToken({ commit }, { cb = () => { } }) {
    apiV1.verifyToken(
      () => {
        commit("authSuccess");
        cb();
      },
      { token: JSON.parse(localStorage.getItem("token")) },
      async (res) => {
        const token = await res;
        if (token.code) {
          commit("authFailure");
          commit("logout");
        }
      }
    );
  },
  dropToken({ commit }, { cb = () => { }, err }) {
    apiV1.dropToken(
      () => {
        commit("logout");
        cb();
      },
      JSON.parse(localStorage.getItem("token")),
      err
    );
  },
  logout({ commit }) {
    commit("logout");
  },
};

// mutations
const mutations = {
  setAccess(state, value) {
    state.access = value;
  },
  setRefresh(state, value) {
    state.refresh = value;
  },
  loginSuccess(state, user) {
    state.loggedIn = true;
    user.face = user.face + `?r=${Math.floor(Math.random() * 100)}`;
    user.document = user.document + `?r=${Math.floor(Math.random() * 100)}`;
    state.user = user;
  },
  loginFailure(state) {
    state.loggedIn = false;
    state.user = null;
  },
  logout(state) {
    // localStorage.removeItem('user');
    localStorage.removeItem("token");
    state.loggedIn = false;
    state.user = null;
    state.access = null;
  },
  authSuccess(state) {
    state.loggedIn = true;
  },
  authFailure(state) {
    state.loggedIn = false;
  },
  setUpdateUser(state, value) {
    state.user[value.str] = value.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
