import * as apiV1 from '@/network/v1/index';
import { useFormatter } from "@/utils/formatter.js";

const state = {
    results: [],
    count: null,
    answer: []
};

// getters
const getters = {
    getResults: (state) => state.results,
    getCount: (state) => state.count,
    getAnswer: (state) => state.answer,

};

// actions
const actions = {
    getFaq({ commit },) {
        apiV1.getFaq((data, count) => {
            commit('setFaq', data);
            commit('setCount', count);
        })
    },
    getFaqId({ commit }, id) {
        apiV1.getFaqId(data => {
            commit('setFaqId', data);
        }, id)
    }
};

// mutations
const mutations = {
    setFaq(state, value) {
        state.results = value
    },
    setCount(state, value) {
        state.count = value
    },
    setFaqId(state, value) {
        const newArr = state.results.map(item => {
            // console.log(item.question);
            if (item.id == value.id) {
                const { wrapperImg } = useFormatter()
                value.answer = wrapperImg(value.answer)
                return item = { ...item, ...value }
            } else {
                return item
            }
        })
        state.results = newArr
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
