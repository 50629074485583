import { onBeforeUnmount, onMounted } from "vue";

export function useClickOutside(someRef, cb) {
  if (!someRef) return;
  let listener = (e) => {
    if (
      e.target === someRef.value ||
      e.composedPath().includes(someRef.value)
    ) {
      return;
    }

    if (typeof cb === "function") {
      cb();
    }
  };

  onMounted(() => {
    window.addEventListener("click",  listener);
  });

  onBeforeUnmount(() => {
    window.addEventListener("click", listener);
  });

  return {
    listener,
  };
}
