<template>
  <Header />
  <main>
    <router-view v-slot="{ Component, route }">
      <transition mode="out-in" name="slide-fade">
        <div :key="route.name">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>
  </main>
  <transition name="zoom-in">
    <ModalContainer v-show="isUnLoad && data.title != null">
      <div class="re-loaded-content">
        <h1 class="title primary-title">{{ data.title }}</h1>
        <p class="description primary-text">{{ data.description }}</p>
        <PrimaryButton
          @click="isUnLoad = !isUnLoad"
          :title="$t('buttons.thanks')"
        />
      </div>
    </ModalContainer>
  </transition>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ModalContainer from "@/components/ModalContainer.vue";
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getInfoModal } from "@/network/v1";
import PrimaryButton from "./components/PrimaryButton.vue";

export default {
  components: { Header, ModalContainer, Footer, PrimaryButton },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isUnLoad = ref(true);
    const data = ref({});

    watch(
      () => route.name,
      () => {
        if (localStorage.getItem("token")) {
          store.dispatch("auth/verifyToken", {
            cb: () => {},
          });
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      isUnLoad.value = true;

      getInfoModal((res) => {
        data.value = res;
      });
    });
    return {
      isUnLoad,
      data,
      route,
    };
  },
};
</script>

<style lang="scss">
:root {
  // light
  --primary-font-color: #323232;
  --secondary-font-color: #fff;
  --highlight-font-color: #007bff;
  --primary-bg: 255, 255, 255;
  --secondary-bg: 18, 18, 18;
  --background-color: #cecece;
  --foreground-color: #121212;

  --gray-font-color: #777777;
  //size
  --header-width: 95%; // dark
  --container-width: 1110px;
  --gap: 30px;
  --primary-blue: #377dff;
  --dark-blue: #18214d;
  --light-gray: #d0d4ea;
  --bg-light-grey: #f2f3f9;
  --white: #ffffff;
  --purple: #7b61ff;
  --neutrals: #25324b;
  --neutrals60: #7c8493;
  --border-light: #d6ddeb;
  --text-gray: #5c6077;
  --error-color: #ed3434;
  --success-color: #56cdad;
  --disabled-color: #9fa4bc;

  &[data-theme="dark"] {
    --primary-font-color: #fff;
    --secondary-font-color: #121212;
    --highlight-font-color: #0063ce;
    --primary-bg: 18, 18, 18;
    --secondary-bg: 255, 255, 255;
    --highlight-bg-color: #0056ba;
    --background-color: #121212;
    --foreground-color: #fff;
  }

  color: var(--primary-font-color);
  font-size: max(16px, 0.73vw);
}
.re-loaded-content {
  max-width: 38.75rem;
  padding: 1.88rem;
  background: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem;
  color: #000;
  user-select: none;
  transition: transform 0.4s;
  text-align: center;

  .title {
    font-weight: 600;
  }

  .description {
    font-weight: 500;
    margin: 1rem 0;
  }

  button {
    width: 6rem;
    height: 2rem;
    font-size: 1.4rem;
    background: linear-gradient(to right, #6931a8 15%, #7069ef 99.77%);
    color: #fff;
    font-weight: 500;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 992px) {
  .re-loaded-content {
    width: 90%;
  }
}

.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: transform 0.5s;
}

.zoom-in-enter-from,
.zoom-in-leave-to {
  transform: translateY(-100vw);
}

img[alt] {
  width: 100%;
  color: transparent;
}

// img:after {
//   font-weight: 900;
//   font-family: "Font Awesome 5 Pro";
//   content: "\f071"" Broken Image of " attr(alt);
//   display: block;
//   font-size: 16px;
//   font-style: normal;
//   color: rgb(100, 100, 100);

//   position: absolute;
//   top: 40%;
//   width: 100%;
//   text-align: center;
// }

b,
strong {
  font-weight: bold;
}

blockquote {
  border-left: 0.3em solid grey;
  margin-top: 1em;
  margin-bottom: 1em;
  font-style: italic;
  p {
    padding: 0.6em 2em;
  }
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

i,
em {
  font-style: italic;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  background-color: yellow;
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background-color: var(--foreground-color);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

html {
  background-color: var(--primary-bg);
  font-family: "Inter", sans-serif;
  height: 100%;
  // overflow-x: hidden;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  box-sizing: border-box;
  vertical-align: baseline;
  // transition: all 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  // transition: all 0.4s ease-in-out;
}

img {
  background-color: lightgray;
  object-fit: cover;
}

.limited-text {
  --snup: 3;
  display: -webkit-box;
  -webkit-line-clamp: var(--snup);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s ease !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s ease-in;
}

.slide-fade-enter-from {
  transform: translateY(4rem);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(4rem);
}

.gradient-btn {
  background: linear-gradient(91.6deg, #6931a8 0.15%, #7069ef 99.77%);
  padding: 0 0.8em;
  border-radius: 0.3em;
  color: #f5f5f5;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("~@/assets/svg-icon/shevron-right.svg") no-repeat;
  // background-size: 20px;
  background-position: center right 0.5em;
  width: calc(100% + 1.5em);
}
select::-ms-expand {
  display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
}
.no-valid {
  min-height: 1.8rem;
  line-height: 1.8;
  color: #ff0000;
  font-size: 0.875em !important;
}
.error-border.text-input {
  border-bottom: 1px solid #ff0000;
}

.error-round-border {
  border: 1px solid #ff0000 !important;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

.input-container {
  width: 75%;
}

.text-input {
  border: none;
  outline: none;
  color: var(--primary-font-color);
  padding: 0.5em 0;
  border-bottom: 1px solid #282828;
  width: 100%;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gray-font-color);
    opacity: 1; /* Firefox */
    text-transform: none;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--gray-font-color);
    text-transform: none;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--gray-font-color);
    text-transform: none;
  }
}

.border-input {
  border: 1px solid #000000;
  border-radius: 0.3em;
  outline: none;
  padding: 0.2em 0.5em;
}
.next-btn {
  font-size: 1.325em;
  line-height: 1.6;
  margin-top: 1em;
  margin-bottom: 1.8em;
  padding-right: 1.05em;
  padding-left: 1.05em;
  // border-radius: 50px;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.icon-back {
  font-size: 2.2em;
  margin-bottom: 2rem;
  margin-top: 1.1em;
  cursor: pointer;
}
.custom-file-input {
  background: #e6e6e6;
  border-radius: 9px;
  padding: 1em 0;
  font-weight: 500;
  font-size: 15.3253px;
  line-height: 22px;

  color: #6f6f6f;
}

.hover-effect {
  transition: all 0.3s ease;

  &:hover {
    color: var(--primary-blue);
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1 0 auto;
}

body {
  height: 100%;
}

.container {
  max-width: 75rem;
  padding: 0 0.9375rem;
  margin: 0 auto;
}

.family {
  &-poppins {
    font-family: "Poppins", sans-serif;
  }
}

.primary-title {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 144%;
  color: var(--dark-blue);
}

.primary-text {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 180%;
  color: var(--text-gray);
}

@keyframes progress-filler {
  to {
    width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  :root {
    font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
  :root {
    font-size: 14px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("~@/assets/svg-icon/shevron-right.svg") no-repeat;
    background-size: 15px;
    background-position: center right 0.5em;
    width: calc(100% + 1.5em);
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 1.5625rem;
  }
  .primary-title {
    font-size: 1.3rem;
  }

  .primary-text {
    font-size: 1.05rem;
  }

  .input-container {
    width: 100%;
  }
  .icon-back {
    margin: 0em;
  }
  .next-btn {
    font-size: 1.325em;
    line-height: 1;
    margin-top: 1.8em;
    margin-bottom: 1.8em;
    padding-right: 1.05em;
    padding-left: 1.05em;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
    width: 100%;
  }
}

@media screen and (max-width: 525px) {
  :root {
    font-size: 16px;
  }
  .primary-text {
    font-size: 0.75rem;
  }

  .container {
    padding: 0 0.94rem;
  }
}
</style>
