import * as apiV1 from '@/network/v1/index';

const state = {
    user: {},
    choicesLabel: null,
    choicesDoc: '',
};

// getters
const getters = {
    getchoicesLabel: (state) => state.choicesLabel,
    getUser: (state) => state.user,
    getDocType: (state) => state.choicesDoc
};

// actions
const actions = {
    getAllLabel({ commit }, choices) {
        apiV1.getAllLabel(data => {
            commit('setAllLabel', data);
        }, choices)
    },
    getUniqueUser({ commit }, { cb = () => { }, phone, err }) {
        apiV1.getUniqueUser((data) => {
            commit('setUser', phone)
            cb(data)
        }, phone, err
        )
    }

};

// mutations
const mutations = {
    setUser(state, value) {
        state.user = { ...state.user, ...value }
    },

    deleteKeyUser(state, value) {
        delete state.user[value]
    },
    setAllLabel(state, value) {
        state.choicesLabel = value
    },
    setDocType(state, value) {
        state.choicesDoc = value
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
