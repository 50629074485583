import * as apiV1 from '@/network/v1/index';
const state = {
    periodRegister: {},
    latestPeriod: null,
    appeal: {},
    period: {
        topik1: [],
        topik2: []
    }
};

// getters
const getters = {
    getPeriodRegister: (state) => state.periodRegister,
    getLatestPeriod: (state) => state.latestPeriod,
    getPeriod: (state) => state.period,
    getPeriodId: (state) => state.latestPeriod?.id

};

// actions
const actions = {
    getLatestPeriod({ commit }, { cb, token, isIBT=false, err }) {
        apiV1.getLatestPeriod((data) => {
            commit('setLatestPeriod', data);
            if (data.period_list) {
                commit('setPeriod', data);
            }
            cb(data)
        }, token, isIBT, err)
    },
    periodId({ commit }, { cb = () => { }, id, token }) {
        apiV1.periodId((data) => {
            commit('setPeriod', data);
            cb();
        }, id, token)
    },
    getRegisterPeriod({ commit }, { cb = () => { } }) {
        apiV1.getRegisterPeriod((data) => {
            commit("setPeriodRegister", data)
            cb(data)
        })
    },
    createAppeal({ commit }, { cb = () => { }, id, token, err }) {
        apiV1.createAppeal((data) => {
            commit('setAppeal', data);
            cb(data);
        }, id, token, err)
    }
};

// mutations
const mutations = {
    setLatestPeriod(state, value) {
        state.latestPeriod = value
    },
    setPeriod(state, value) {
        state.period.topik1 = []
        state.period.topik2 = []

        value.period_list.forEach(item => {
            if (item.topik_type == 1) {
                state.period.topik1.push(item)
            } else {
                state.period.topik2.push(item)
            }
        });
        delete value.period

        state.period = { ...state.period, ...value }
    },
    setPeriodRegister(state, value) {
        state.periodRegister = value
    },
    setAppeal(state, value) {
        state.appeal = value
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
