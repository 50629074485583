import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
import {
  getBrowserLocale,
  DEFAULT_LOCALE,
  i18n,
  loadLocaleMessages,
  setI18nLanguage,
} from "../i18n";
import { SUPPORT_LOCALES } from "@/utils/constants";

const routes = [];

const childRoutes = [
  {
    path: "",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/HomePage.vue"),
  },
  {
    path: "instruction",
    name: "Instruction",
    component: () =>
      import(
        /* webpackChunkName: "Instruction" */ "@/views/InstructionPage.vue"
      ),
  },
  {
    path: "register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/RegisterPage.vue"),
  },
  // {
  //   path: "test_register_page",
  //   name: "RegisterTest",
  //   component: () =>
  //     import(/* webpackChunkName: "register" */ "@/views/RegisterPageTest.vue"),
  // },
  {
    path: "auth",
    name: "Auth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/AuthPage.vue"),
  },
  {
    path: "reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/components/register/ResetPassword.vue"
      ),
  },
  {
    path: "confirm-code",
    name: "ConfirmCode",
    component: () =>
      import(
        /* webpackChunkName: "confirm-code" */ "@/components/register/ConfirmCode.vue"
      ),
  },
  {
    path: "question",
    name: "Question",
    component: () =>
      import(/* webpackChunkName: "question" */ "@/views/QuestionPage.vue"),
  },
  {
    path: "announcing",
    name: "Announcing",
    component: () =>
      import(/* webpackChunkName: "announcing" */ "@/views/AnnouncingPage.vue"),
  },
  {
    path: "announcing/:slug",
    name: "AnnouncementDetail",
    component: () =>
      import(
        /* webpackChunkName: "AnnouncementDetail" */ "@/views/AnnouncementDetail.vue"
      ),
  },
  {
    path: "account",
    name: "Account",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "@/views/AccountPage.vue"),
    children: [
      {
        path: "info",
        name: "AccountInfo",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/components/Account/AccountInfo.vue"),
      },
      {
        path: "",
        name: "AccountAnketa",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/Anketa.vue"),
      },
    ],
  },
  // {
  //   path: "person",
  //   name: "Person",
  //   component: () =>
  //     import(/* webpackChunkName: "person" */ "@/views/PersonPage.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   children: [
  //     {
  //       path: "",
  //       name: "Anketa",
  //       meta: {
  //         requiresAuth: true,
  //       },
  //       component: () => import("@/views/Anketa.vue"),
  //     },
  //     {
  //       path: "person-information",
  //       name: "PersonInfo",
  //       meta: {
  //         requiresAuth: true,
  //       },
  //       component: () => import("@/components/person/PersonInfo.vue"),
  //     },
  //     {
  //       path: "person-password",
  //       name: "PersonPassword",
  //       meta: {
  //         requiresAuth: true,
  //       },
  //       component: () => import("@/components/person/PersonPassword.vue"),
  //     },
  //   ],
  // },
  {
    path: "registrationExem",
    name: "RegistrationExem",
    component: () =>
      import(
        /* webpackChunkName: "person" */ "@/views/RegistrationExemPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "registrationIBT",
    name: "RegistrationIBT",
    component: () =>
      import(
        /* webpackChunkName: "person" */ "@/views/RegistrationExemPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "anketa-admin",
    name: "BackAnketa",
    component: () =>
      import(/* webpackChunkName: "BackAnketa" */ "@/views/BackAnketa.vue"),
    children: [
      {
        path: "",
        name: "BackAnketaView",
        component: () => import("@/components/anketa/BackAnketaView.vue"),
      },
    ],
  },
];

const routeNames = [];

const checkIfArrayIsUnique = (myArray) => {
  return myArray.length === new Set(myArray).size;
};

const recursiveChildren = (locale, children) => {
  if (!children || children.length === 0) {
    return;
  }
  const recChildren = [];
  children.forEach((child) => {
    routeNames.push(child.name);
    recChildren.push({
      ...child,
      name: `${locale}_${child.name}`,
      children: recursiveChildren(locale, child.children),
    });
  });
  return recChildren;
};

SUPPORT_LOCALES.forEach((locale) => {
  routes.unshift({
    path: `/${locale === DEFAULT_LOCALE ? "" : locale}`,
    component: {
      template: "<router-view></router-view>",
    },
    children: recursiveChildren(locale, childRoutes),
  });
});

if (checkIfArrayIsUnique(routeNames))
  throw new Error("Route names must be unique");
const newLocale = getBrowserLocale();

routes.push({
  path: "/:catchAll(.*)",
  redirect: `${newLocale !== DEFAULT_LOCALE ? newLocale : "/"}`,
  // name: "NotFound",
  // component: () => import('@/views/HomePage.vue')
});

const isServer = typeof window === "undefined";
const history = isServer ? createMemoryHistory() : createWebHistory();

const beforeEach = async (to, from, next) => {
  const token = JSON.parse(localStorage.getItem("token"));

  if (!isServer) {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  let paramsLocale;
  SUPPORT_LOCALES.forEach((locale) => {
    const regex = new RegExp(
      `/${locale}/${locale.length + 1 === to.path.length ? `|/${locale}` : ""}`
    );
    let m;
    if ((m = regex.exec(to.path)) !== null) {
      paramsLocale = m[0].slice(1, locale.length + 1);
    }
  });

  paramsLocale = paramsLocale === undefined ? DEFAULT_LOCALE : paramsLocale;

  // use locale if paramsLocale is not in SUPPORT_LOCALES
  if (!SUPPORT_LOCALES.includes(paramsLocale)) {
    return next({
      name: `${newLocale}_Home`,
    });
  }

  if (to.meta.requiresAuth) {
    if (!token) {
      return next({ name: `${paramsLocale}_Auth`, query: { next: to.name } });
    }
  }

  // load locale messages
  if (!i18n.global.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale);
  }

  // set i18n language
  setI18nLanguage(i18n, paramsLocale);

  return next();
};

export default function () {
  const router = createRouter({
    history,
    routes,
    scrollBehavior() {
      return {
        x: 0,
        y: 0,
      };
    },
  });

  router.beforeEach(beforeEach);
  return router;
}
