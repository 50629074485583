// export const BASE_URL = 'https://al-electronics.uz/'

export const SUPPORT_LOCALES = ["uz", "ru", "ko"];
// export const SUPPORT_LOCALES = ['en', 'ru', 'uz', 'uz_to', 'ar', 'cn', 'de', 'es', 'fr', 'jp', 'tk']

export const SUPPORT_LOCALES_NAMES = {
  en: "English",
  ru: "Русский",
  es: "Español",
  fr: "Français",
  de: "Deutsch",
  jp: "日本語",
  ar: "العربية",
  tk: "Türkçe",
  cn: "简体中文",
  uz: "O'zbekcha",
  uz_to: "Ўзбекча",
};

export const SUPPORT_LOCALES_NAMES_SLUG = {
  en: "English",
  ru: "Русский",
  es: "Español",
  fr: "Français",
  de: "Deutsch",
  jp: "Japanese",
  ar: "Arabic",
  tk: "Türkçe",
  cn: "Chinese",
  uz: "O'zbekcha",
  uz_to: "Ўзбекча",
};

// // tg channels for all languages
// export const CHANNELS = {
//   'en': 'uzeng',
//   'ru': 'uzru',
//   'uz': 'uz',
//   'uz-to': 'uzb',
// }

export const months = {
  0: "january",
  1: "february",
  2: "march",
  3: "april",
  4: "may",
  5: "june",
  6: "july",
  7: "august",
  8: "september",
  9: "october",
  10: "november",
  11: "december",
};

export const registerDates = [
  {
    id: 0,
    exam_time: "2022-08-28T18:35:30+05:00",
    creation_date: "2022-03-23T17:35:30+05:00",
    exam_address: "sdfffsddsffe",
    topik_type: 1,
    declined: true,
    anketa: {
      id: 2,
      registration_number: "4534",
      declined: false,
      topik_type: 1,
      exam_time: null,
      region_name: "tashkent 1",
      region_name_ko: "tashkent 1",
      first_name: "sdsffeaefw we",
      first_name_ko: "안녕하세요",
      last_name: "asdadqwd",
      last_name_ko: "안녕하세요",
      sex: "F",
      birth_date: "2022-03-23",
      address: "Узбекистан, bjisfdoisfhoi, uoinoilfhols, oiionfoisoilfolinh",
      postal_index: "100105",
      phone: "998909415748",
      home_phone: "7122231231",
      email: "sayidisayev5@gmail.com",
      period_number: 123,
      id_doc: "AA324233",
      face: "https://picsum.photos/200/300",
      document: "https://picsum.photos/1000/1200",
      exam_address: "sdfffsddsffe",
      creation_date: "2022-08-23T17:35:30+05:00",
      citizenship: 1,
      nationality: 1,
      purpose: 1,
      inform_source: 1,
      employment: 1,
    },
  },
  {
    id: 1,
    exam_time: "2022-02-23T17:35:30+05:00",
    creation_date: "2022-03-23T17:35:30+05:00",
    exam_address: "sdfffsddsffe",
    topik_type: 2,
    declined: true,
    anketa: {
      id: 2,
      registration_number: "2234",
      declined: false,
      topik_type: 1,
      exam_time: null,
      region_name: "asasdasd 1",
      region_name_ko: "asdasddas 1",
      first_name: "sdsffeaefw we",
      first_name_ko: "안녕하세요",
      last_name: "asdadqwd",
      last_name_ko: "안녕하세요",
      sex: "F",
      birth_date: "2022-03-23",
      address: "Узбекистан, bjisfdoisfhoi, uoinoilfhols, oiionfoisoilfolinh",
      postal_index: "100105",
      phone: "998909415748",
      home_phone: "7122231231",
      email: "sayidisayev5@gmail.com",
      period_number: 123,
      id_doc: "AA324233",
      face: "https://picsum.photos/200/300",
      document: "https://picsum.photos/1000/1200",
      exam_address: "sdfffsddsffe",
      creation_date: "2022-03-23T17:35:30+05:00",
      citizenship: 1,
      nationality: 1,
      purpose: 1,
      inform_source: 1,
      employment: 1,
    },
  },
];

export const contacts = "+998 90 164 50 50";
