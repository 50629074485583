<template>
  <div class="contacts flex">
    <div class="lang" ref="lang" :class="{ active: langActive }">
      <div class="lang-list">
        <div class="selected-lang" @click="langActive = !langActive">
          <svg
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12ZM3.64232 8.65452C4.5557 6.37479 6.3748 4.5557 8.65452 3.64232C8.11964 4.67487 7.70006 5.96224 7.41726 7.41726C5.96224 7.70006 4.67487 8.11964 3.64232 8.65452ZM15.3455 3.64232C15.8804 4.67487 16.2999 5.96224 16.5827 7.41726C18.0378 7.70006 19.3251 8.11964 20.3577 8.65452C19.4443 6.37479 17.6252 4.5557 15.3455 3.64232ZM3 12C3 11.1008 4.62868 10.125 7.11448 9.53819C7.03905 10.3308 7 11.155 7 12C7 12.845 7.03905 13.6692 7.11448 14.4618C4.62868 13.875 3 12.8992 3 12ZM17 12C17 12.845 16.961 13.6692 16.8855 14.4618C19.3713 13.875 21 12.8992 21 12C21 11.1008 19.3713 10.125 16.8855 9.53819C16.961 10.3308 17 11.155 17 12ZM3.64232 15.3455C4.67487 15.8804 5.96224 16.2999 7.41726 16.5827C7.70006 18.0378 8.11964 19.3251 8.65452 20.3577C6.37479 19.4443 4.5557 17.6252 3.64232 15.3455ZM16.5827 16.5827C16.2999 18.0378 15.8804 19.3251 15.3455 20.3577C17.6252 19.4443 19.4443 17.6252 20.3577 15.3455C19.3251 15.8804 18.0378 16.2999 16.5827 16.5827ZM9 12C9 11.0097 9.06211 10.0597 9.17262 9.17262C10.0597 9.06211 11.0097 9 12 9C12.9903 9 13.9403 9.06211 14.8274 9.17262C14.9379 10.0597 15 11.0097 15 12C15 12.9903 14.9379 13.9403 14.8274 14.8274C13.9403 14.9379 12.9903 15 12 15C11.0097 15 10.0597 14.9379 9.17262 14.8274C9.06211 13.9403 9 12.9903 9 12ZM9.53819 16.8855C10.125 19.3713 11.1008 21 12 21C12.8992 21 13.875 19.3713 14.4618 16.8855C13.6692 16.961 12.845 17 12 17C11.155 17 10.3308 16.961 9.53819 16.8855ZM12 3C11.1008 3 10.125 4.62868 9.53819 7.11448C10.3308 7.03905 11.155 7 12 7C12.845 7 13.6692 7.03905 14.4618 7.11448C13.875 4.62868 12.8992 3 12 3Z"
              fill="#377DFF"
            />
          </svg>
          <span> {{ $t(`main.short_lang.${i18n.global.locale.value}`) }} </span>
        </div>
        <template v-for="(lang, index) in SUPPORT_LOCALES" :key="index">
          <div
            v-if="lang !== i18n.global.locale.value"
            class="lang-item"
            @click="switchLanguage(lang), (langActive = false)"
          >
            {{ $t(`main.short_lang.${lang}`) }}
          </div>
        </template>
      </div>
    </div>
    <a href="https://t.me/topik_kec" class="telegram-link flex">
      <svg
        width="2.25rem"
        height="2.25rem"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="18" fill="#32A9E6" />
        <path
          d="M8.16484 17.968C8.16484 17.968 16.5681 14.4557 19.4825 13.219C20.5997 12.7243 24.3884 11.1413 24.3884 11.1413C24.3884 11.1413 26.1371 10.4487 25.9914 12.1306C25.9428 12.8232 25.5542 15.2472 25.1656 17.8691C24.5827 21.5793 23.9513 25.6358 23.9513 25.6358C23.9513 25.6358 23.8541 26.7736 23.0284 26.9715C22.2026 27.1694 20.8426 26.2789 20.5997 26.0811C20.4054 25.9326 16.9567 23.7065 15.6938 22.6182C15.3537 22.3214 14.9652 21.7277 15.7423 21.0352C17.491 19.4027 19.5797 17.3744 20.8426 16.0882C21.4255 15.4946 22.0083 14.1094 19.5797 15.7914C16.1309 18.2154 12.7308 20.491 12.7308 20.491C12.7308 20.491 11.9536 20.9857 10.4964 20.5405C9.03917 20.0952 7.33909 19.5016 7.33909 19.5016C7.33909 19.5016 6.17332 18.7596 8.16484 17.968Z"
          fill="white"
        />
      </svg>
    </a>
    <a href="tel:+998712918182" target="_blank" class="phone flex">
      <svg
        width="1.5rem"
        height="1.5rem"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.8565 8.3379C11.3746 7.63969 11.5604 6.90384 10.9744 6.25431C9.65949 4.41433 8.77515 3.27649 8.22044 2.72854C7.16589 1.6868 5.43112 1.82771 4.51779 2.72765C4.02723 3.21101 3.86116 3.377 3.35747 3.88861C0.551816 6.69577 2.26285 12.63 6.81139 17.183C11.3589 21.735 17.2926 23.4469 20.1041 20.6338C20.5689 20.1858 20.9624 19.792 21.2728 19.464C22.1678 18.518 22.3037 16.8597 21.2669 15.7824C20.7354 15.2301 19.6503 14.3885 17.7329 13.0169C17.1457 12.4918 16.4494 12.6056 15.811 13.0244C15.504 13.2259 15.2805 13.4297 14.8585 13.8521L14.0923 14.6187C13.9914 14.7197 12.621 14.0334 11.2907 12.7018C9.95972 11.3695 9.2739 9.99906 9.37435 9.89862L10.1411 9.13142C10.275 8.99742 10.339 8.93254 10.4211 8.84627C10.5921 8.66654 10.7337 8.50344 10.8565 8.3379ZM15.5057 16.0329L16.2721 15.2661C16.5044 15.0337 16.6549 14.8907 16.7773 14.7922C18.457 15.9984 19.4297 16.7568 19.8271 17.1697C20.0656 17.4176 20.0286 17.8699 19.8212 18.089C19.5342 18.3924 19.1613 18.7655 18.7037 19.2068C16.8857 21.0256 12.0959 19.6437 8.22513 15.7691C4.35315 11.8933 2.97188 7.10279 4.7762 5.29748C5.27786 4.78798 5.4368 4.62912 5.92035 4.15265C6.10166 3.97401 6.59552 3.93389 6.81608 4.15176C7.24314 4.57364 8.03534 5.58799 9.20071 7.21133C9.14038 7.28623 9.06503 7.37087 8.97333 7.46724C8.90603 7.53798 8.84996 7.59481 8.72738 7.71751L7.96136 8.48396C6.65821 9.787 7.76802 12.0047 9.87697 14.1157C11.9845 16.2253 14.203 17.3364 15.5057 16.0329Z"
          fill="#377DFF"
        />
      </svg>
      <span class="hover-effect"> {{ $t("header.contact") }}</span>
    </a>
    <div class="address flex" @click="emits('setMenu')">
      <svg
        width="1.5rem"
        height="1.5rem"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 23.3276L12.6577 22.7533C18.1887 17.9237 21 13.7068 21 10C21 4.75066 16.9029 1 12 1C7.09705 1 3 4.75066 3 10C3 13.7068 5.81131 17.9237 11.3423 22.7533L12 23.3276ZM12 20.6634C7.30661 16.4335 5 12.8492 5 10C5 5.8966 8.16411 3 12 3C15.8359 3 19 5.8966 19 10C19 12.8492 16.6934 16.4335 12 20.6634ZM12 5C14.7614 5 17 7.23858 17 10C17 12.7614 14.7614 15 12 15C9.23858 15 7 12.7614 7 10C7 7.23858 9.23858 5 12 5ZM9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10Z"
          fill="#377DFF"
        />
      </svg>

      <span>
        {{ $t("header.location") }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { useLanguage } from "@/utils/language";
import { SUPPORT_LOCALES } from "@/utils/constants";
import { ref, defineEmits } from "vue";
import { useClickOutside } from "@/utils/useClickOutside";
const { i18n, switchLanguage } = useLanguage();

const emits = defineEmits(["setMenu"]);
const langActive = ref(false);
const lang = ref(null);

useClickOutside(lang, () => {
  langActive.value = false;
});
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.contacts {
  .lang {
    color: var(--primary-blue);
    max-height: 2rem;
    position: relative;
    z-index: 3;

    &.active {
      .lang-list {
        max-height: 6rem;
        box-shadow: 0px 0.5rem 1.75rem -0.38rem rgba(24, 39, 75, 0.12),
          0px 1.13rem 5.5rem -0.25rem rgba(24, 39, 75, 0.14);
      }
      .selected-lang {
        border-radius: 0;
        border-top-left-radius: 0.31rem;
      }
    }

    &-list {
      height: 100%;
      overflow: hidden;
      max-height: 2.2rem;
      transition: all 0.3s ease;
      background: var(--white);
      border-radius: 0.31rem;
      border: 0.06rem solid var(--primary-blue);
    }

    &-item {
      display: flex;
      justify-content: flex-end;
      padding: 0.3125rem 0.5rem;
      padding-right: 2.2rem;
      cursor: pointer;
    }

    .selected-lang {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0.3125rem 0.5rem;
      padding-right: 2.2rem;
      transition: all 0.3s ease;
    }

    span {
      font-weight: 500;
      font-size: 1em;
    }

    svg {
      margin-right: 0.625rem;
    }
  }

  svg {
    margin-right: 0.625rem;
  }

  .telegram-link {
    margin: 0 0.9rem;

    svg {
      margin: 0;
    }
  }

  .phone {
    font-weight: 500;
    font-size: 1em;
    color: var(--dark-blue);
    margin: 0 0.9375rem;

    span {
      width: max-content;
    }
  }

  .address {
    margin-left: 0.9375rem;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 180%;

    span {
      max-width: 17.5rem;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .contacts {
    svg {
      margin-right: 0;
    }
    .phone {
      margin: 0 0.5rem;
      span {
        display: none;
      }
    }
    .address {
      margin-left: 0.5rem;
      span {
        display: none;
      }
    }

    .lang {
      margin: 0 0.5rem;
    }

    .telegram-link {
      margin: 0 0.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .contacts {
    .telegram-link {
      width: 1.88rem;
    }

    .address {
      svg {
        width: 1.2rem;
      }
    }

    .phone {
      svg {
        width: 1.2rem;
      }
    }

    .lang {
      span {
        font-size: 0.8em;
      }

      svg {
        width: 1.25rem;
        margin-right: 0.3rem;
      }

      &-item {
        font-size: 0.8em;
        padding-right: 1.8rem;
      }

      .selected-lang {
        padding-right: 1.8rem;
      }
    }
  }
}
</style>
