<template>
  <div class="modal-container">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(66, 66, 66, 0.51);
  backdrop-filter: blur(5px);
  z-index: 50;
  width: 100%;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // padding: 2rem;
    margin: 0 auto;
    width: 90%;
    @media screen and (max-width: 576px) {
      margin: 0;
      // width: 80%;
      padding: 1em;
    }
  }
}
</style>