import * as apiV1 from "@/network/v1/index";

const state = {
    calendar: {},
};

// getters
const getters = {
    getCalendar: (state) => state.calendar,
};

// actions
const actions = {
    getcalendar({ commit }, { cb = () => { }, start, end }) {
        apiV1.calendar((data) => {
            commit("setCalendar", data);
            cb();
        }, start, end);
    },
};

// mutations
const mutations = {
    setCalendar(state, value) {
        state.calendar = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
