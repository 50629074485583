const DEBUG_URL = "https://test-topik.al-electronics.uz/";
export const BASE_URL =
  process.env.VUE_APP_TESTING 
  ? "https://test-topik.al-electronics.uz/" : process.env.NODE_ENV === "production"
  ? "https://topik.al-electronics.uz/" : DEBUG_URL;
// export const BASE_URL = "http://localhost:8000/";

const API_V1 = "/api/v1";
import { i18n } from "@/i18n";

export async function getUniqueUser(cb, data, err) {
  let choices = "?";
  for (const field in data) {
    choices += `${field}=${data[field]}&`;
  }
  fetch(
    `${BASE_URL}${
      i18n.global.locale.value
    }${API_V1}/user-unique-check/${choices.slice(0, -1)}`
  )
    .then((res) => {
      cb(res.json());
    })
    .catch((res) => {
      throw err(res);
    })
    .then((json) => {
      cb(json);
    });
}

export async function getAllLabel(cb, data) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/choices/?choices=${data}`
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

//Singn-up
export async function signUp(cb, form, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/register/`, {
    method: "POST",
    body: form,
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) throw err(res);
      return await cb(res);
    });
}
// get-Me
export async function getMe(cb, token, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/user/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((e) => {
      err(e);
      console.log(e);
    })
    .then((res) => {
      if (!res.ok) err(res);
      return res.json();
    })
    .then((res) => {
      cb(res);
    });
}

// Auth people
export async function getTokens(cb, data, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/token/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return cb(await res.json());
    });
}

export async function verifyToken(cb, data, err) {
  fetch(`${BASE_URL}auth/jwt/verify/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}
export async function dropToken(cb, token, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/drop-token/`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

// Update User
export async function updateUser(cb, token, newData, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/user/`, {
    method: "PATCH",
    body: newData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return cb(await res.json());
    });
}

// faq
export async function getFaq(cb) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/faq`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json["results"], json["count"]);
    });
}

export async function getFaqId(cb, id) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/faq/${id}/`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

//  Period
export async function getLatestPeriod(cb, token, isIBT, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/latest-period?is_ibt=${isIBT}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return cb(await res.json());
    });
}

export async function periodId(cb, id, token) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/period/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}
export async function getRegisterPeriod(cb) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/latest-registration-period/`
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

// Appeals
export async function getAppealsList(cb, token) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/user-appeals-list/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json["results"]);
    });
}

export async function getAppealsDetail(cb, token, id) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/user-appeal/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getAdminAppealsDetail(cb, id) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/admin-appeal/${id}`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getAllChoices(cb) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/choices-appeal/?choices=citizenship,nationality,region,employment,country,purpose,inform_source`,
    {}
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function createAppeal(cb, id, data, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/appeal/${id}/`, {
    headers: {
      Authorization: `Bearer ${data}`,
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getArticlesList(cb) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/articles/`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json["results"], json["count"]);
    });
}

export async function getArticlesListPagination(cb, limit, offset) {
  fetch(
    `${BASE_URL}${
      i18n.global.locale.value
    }${API_V1}/articles?limit=${limit}&offset=${limit * offset}`
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json["results"], json["count"]);
    });
}

export async function getLimiteArticlesList(cb, limit) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/articles/?limit=${limit}`
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json["results"], json["count"]);
    });
}

export async function getArticleId(cb, id) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/article/${id}/`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function calendar(cb, start, end) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/calendar/?start=${start}&end=${end}`
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function phoneAuth(cb, data, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/phone-auth/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function sendCode(cb, data, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/send-code/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) return err(await res.json());
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getInfoModal(cb) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/info-model/`)
    .then(async (res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getLastEvents(cb, limit, offset) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/last-events/?limit=${limit}&offset=${offset}`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json['results']);
    });
}

export async function getExamTypes(cb) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/exam-types/`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}
