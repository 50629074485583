import * as apiV1 from '@/network/v1/index';
import { useFormatter } from "@/utils/formatter.js";

const state = {
    articlesList: [],
    articleCount: null,
    articleCurrentPage: 0,
    articleID: {}
};

// getters
const getters = {
    getArticleID: state => state.articleID,
    getArticlesList: state => state.articlesList,
    getArticlesCurrentPage: state => state.articleCurrentPage,
    getArticlesCount: state => state.articleCount
}

// actions
const actions = {
    getArticlesList({ commit }, { cb = () => { } }) {
        apiV1.getArticlesList((res, count) => {
            commit('setArticlesList', res)
            commit('setArticlesCount', count)
            cb()
        })
    },
    getLimiteArticlesList({ commit }, { cb = () => { }, limit }) {
        apiV1.getLimiteArticlesList((res, count) => {
            commit('setArticlesList', res)
            commit('setArticlesCount', count)
            cb()
        }, limit)
    },
    getArticlesListPagination({ commit }, { cb = () => { }, pageSize, load = 1 }) {
        apiV1.getArticlesListPagination((data, count) => {
            for (let i = 1; i <= load; i++) {
                commit('setArticlePagination', data.slice((i - 1) * pageSize, i * pageSize));
                commit('setIncrementArticleCurrentPage');
            }
            commit('setArticlesCount', count);
            cb()
        }, pageSize, state.articleCurrentPage * load)
    },

    getArticleId({ commit }, { cb = () => { }, id }) {
        apiV1.getArticleId((data) => {
            commit('setArticleId', data)
            cb()
        }, id)
    }
}

// mutations
const mutations = {
    setArticlesList(state, value) {
        state.articlesList = value
    },
    setArticleId(state, value) {
        const { wrapperImg } = useFormatter()
        value.description = wrapperImg(value.description)
        state.articleID = value
    },
    setArticlesCount(state, value) {
        state.articleCount = value
    },
    setIncrementArticleCurrentPage(state) {
        state.articleCurrentPage++
    },
    setArticlePagination(state, value) {
        state.articlesList.push(...value)
    },
    flushArticleID(state) {
        state.articleID = {}
    },
    flushArticleList(state) {
        state.articlesList = []
        state.articleCount = null
        state.articleCurrentPage = 0
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
