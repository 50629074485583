import * as apiV1 from '@/network/v1/index';

// import { useFormatter } from "@/utils/formatter.js";

const state = {
    phone: '',
    error: ''
};

// getters
const getters = {
    getPhone: (state) => state.phone,
    getError: (state) => state.error

};

// actions
const actions = {
    sendCode({ commit }, { cb = () => { }, phone, err }) {
        apiV1.sendCode((data) => {
            commit("setPhone", phone);
            cb(data);
        }, phone, err)
    },
    phoneAuth({ commit }, { cb = () => { }, data, err }) {
        apiV1.phoneAuth((d) => {
            cb(d)
            commit("setError", d)
        }, data, err)
    }
};

// mutations
const mutations = {
    setPhone(state, value) {
        state.phone = value.phone
    },
    setError(state, value) {
        state.error = value.error
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
